<template>
  <FormCard v-if="role" :config="general_data" @DataSaved="UpdateData" />
  <div v-else class="loading">
    <CSpinner color="secondary" size="lg" />
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import FormCard from "../../../common/form/Card";

export default {
  model: {
    prop: "role",
    event: "change",
  },
  props: ["role"],
  components: {
    FormCard,
  },
  // watch: {
  //   "pp.category__macrocategory_id": function (nV, oV) {
  //     console.log("changed macrocategory", nV, oV);
  //     // this.UpdateMacrocategoryOptions();
  //     this.pp.category__parent_id = null;
  //     // this.UpdateLtypeOptions();
  //     this.pp.ptype_id = null;
  //   },
  //   "pp.category__parent_id": function (nV, oV) {
  //     console.log("changedparent", nV, oV);
  //     // this.UpdateCategoryOptions();
  //     this.pp.category_id = null;
  //   },
  // },
  methods: {
    UpdateData(data) {
      // console.log("UpdateData")
      // this.role = data
      this.$emit("change", data);
    },
  },
  computed: {
    general_data() {
      return {
        title: this.$t("role.profile.tab_infos.card_public.title"),
        data: this.role,
        fields: {
          info: {
            title: this.$t("models.role.info"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.role.info_info"),
          },
          info_extended: {
            title: this.$t("models.role.info_extended"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.role.info_extended_info"),
          },
          info_contact: {
            title: this.$t("models.role.info_contact"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.role.info_contact_info"),
          },
          desc_zone: {
            title: this.$t("models.role.desc_zone"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.role.desc_zone_info"),
          },
          desc_short: {
            title: this.$t("models.role.desc_short"),
            type: "CKInput",
            size: 12,
            subsize: [2, 10],
            description: this.$t("models.role.desc_short_info"),
          },
        },
        dm: GetDataManager("auth_frole", []),
      };
    },
  },
};
</script>
